import {useI18n} from '@shopify/react-i18n'
import { useRecoilState } from 'recoil'
import { lang as langAtom } from '../atoms'
import en from 'translations/en.json'
import ja from 'translations/ja.json'

const useLang = () => {

  const [ lang, setLang ] = useRecoilState(langAtom)

  const [i18n] = useI18n({
    id: 'T',
    fallback: ja,
    translations(locale) {
      if (locale==='EN') return en
      if (locale==='JA') return ja
    },
  })

  const translate = (i18nName:string, replacement?: any) => i18n.translate(i18nName, replacement)

  return {
    lang,
    setLang,
    translate,
  }
}

export default useLang
