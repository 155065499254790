import * as React from 'react'

type LinkComponentProps = {
  children: React.ReactNode
  href: string
  iff?: boolean
  anchorLink?: boolean
  className?: string
}

const LinkComponent = ({
  children,
  href,
  iff=true,
  anchorLink=false,
  className='',
}:LinkComponentProps) => {
  return (
    <>
      {
        iff && anchorLink && <a className={className}
          href={href}
        >
          {children}
        </a>
      }
      {
        iff && !anchorLink && <a className={className}
          href={href}
          target='_blank'
          rel='norefferer noreferrer'
        >
          {children}
        </a>
      }
    </>
  )
}

export default LinkComponent
