import React from 'react'
import { Banner, BannerStatus } from '@shopify/polaris'
import * as atoms from 'lib/atoms'
import { useRecoilState } from 'recoil'

const BannerCustom = () => {
  const [bannerActive, setBannerActive] = useRecoilState(atoms.bannerOpt)

  return (
    <Banner status={bannerActive?.status} onDismiss={() => setBannerActive({
      active: false,
      message: '',
      status: 'info',
    })}>
      {bannerActive?.message}
    </Banner>
  )
}

export default BannerCustom
