import * as React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const AnchorLinkComponent = ({ children, href, offset=50 }) => {
  return (
    <AnchorLink href={href} offset={offset}
      className='hover:bg-white-milky hover:border-b hover:shadow-md active:bg-green-pale px-1 py-2 rounded-xl mx-4 sm:mx-2 md:mx-3 lg:px-6 lg:py-3'
    >
      {children}
    </AnchorLink>
  )
}

export default AnchorLinkComponent
