import * as React from 'react'
import { Tooltip } from '@shopify/polaris'

/** Cleans up Div component for Tailwind
 *  You might see a warning if the style you are using isn't in below whitelist
 *  If you see this warning, please add the new style to the whitelist in order to use it
 */
type DivComponentProps = {
  children: React.ReactNode
  iff?: boolean
  id?: string
  className?: string
  device?: 'ANY' | 'DESKTOP' | 'MOBILE'
  xl2Screen?: string    // tailwind @media (min-width: 1536px)
  xlScreen?: string    // tailwind @media (min-width: 1280px)
  lgScreen?: string    // tailwind @media (min-width: 1024px)
  mdScreen?: string    // tailwind @media (min-width: 768px)
  smScreen?: string    // tailwind @media (min-width: 640px)
  toolTip?: React.ReactNode
}

const appendPrefix = (s: string, pre: string): string => {
  return s.split(' ')
    .filter(s => !!s)
    .map(s => {
      const prefixed = s.startsWith(pre) ? s : `${pre}:${s}`
      if (!WHITELIST.includes(prefixed)) console.info(prefixed, 'Missing from Div WHITELIST')
      return prefixed
    })
    .join(' ')
}

const DivComponent = ({
  children,
  iff = true,
  id = '',
  className = '',
  device = 'ANY',
  xl2Screen = '',
  xlScreen = '',
  lgScreen = '',
  mdScreen = '',
  smScreen = '',
  toolTip,
}: DivComponentProps) => {

  const formClass = () => {
    const classes = []
    if (device === 'MOBILE') classes.push('hidden sm:block')
    if (device === 'DESKTOP') classes.push('block sm:hidden')
    if (className) classes.push(className)
    if (smScreen) classes.push(appendPrefix(smScreen, 'sm'))
    if (mdScreen) classes.push(appendPrefix(mdScreen, 'md'))
    if (lgScreen) classes.push(appendPrefix(lgScreen, 'lg'))
    if (xlScreen) classes.push(appendPrefix(xlScreen, 'xl'))
    if (xl2Screen) classes.push(appendPrefix(xl2Screen, '2xl'))
    return classes.join(' ')
  }

  return (
    <>
      {
        iff && id && !toolTip && <div id={id} className={formClass()}>
          {children}
        </div>
      }
      {
        iff && !id && !toolTip && <div className={formClass()}>
          {children}
        </div>
      }
      {
        iff && toolTip &&
        <div className='lg:w-full'>
          <Tooltip content={toolTip}>
            <div className={formClass()}>
              {children}
            </div>
          </Tooltip>
        </div>
      }
    </>
  )
}

export default DivComponent

const WHITELIST = [
  'lg:justify-end',
  'lg:pb-[5.5rem]',
  'lg:bg-center',
  'lg:bg-contain',
  'lg:border-0',
  'lg:flex-col',
  'lg:flex-row',
  'lg:flex',
  'lg:gap-0',
  'lg:gap-10',
  'lg:h-101',
  'lg:h-full',
  'lg:items-start',
  'lg:justify-around',
  'lg:max-w-7xl',
  'lg:max-w-screen-lg',
  'lg:max-w-screen-md',
  'lg:mb-1',
  'lg:ml-2',
  'lg:mr-2',
  'lg:mr-16',
  'lg:mx-auto',
  'lg:pl-1',
  'lg:pl-2',
  'lg:pl-6',
  'lg:pl-10',
  'lg:pt-0',
  'lg:pt-2',
  'lg:pt-24',
  'lg:px-0',
  'lg:px-2',
  'lg:px-20',
  'lg:px-1',
  'lg:px-4',
  'lg:px-5',
  'lg:px-6',
  'lg:px-8',
  'lg:py-0',
  'lg:py-8',
  'lg:py-28',
  'lg:py-36',
  'lg:py-4',
  'lg:py-10',
  'lg:py-20',
  'lg:pl-1',
  'lg:px-2',
  'lg:shadow-xl',
  'lg:text-left',
  'lg:text-2xl',
  'lg:text-3xl',
  'lg:text-5xl',
  'lg:w-full',
  'lg:w-[20%]',
  'lg:w-[21rem]',
  'lg:w-52',
  'md:max-w-5xl',
  'md:mb-10',
  'md:p-0',
  'md:pr-[26rem]',
  'md:px-10',
  'md:w-[51rem]',
  'md:w-[50%]',
  'sm:absolute',
  'sm:block',
  'sm:flex',
  'sm:h-[54rem]',
  'sm:items-center',
  'sm:justify-center',
  'sm:left-0',
  'sm:mr-0',
  'sm:pb-0',
  'sm:pl-1',
  'sm:pl-2',
  'sm:pl-8',
  'sm:pt-0',
  'sm:pt-10',
  'sm:pt-99',
  'sm:px-6',
  'sm:px-0',
  'sm:py-0',
  'sm:py-4',
  'sm:py-10',
  'sm:py-56',
  'sm:right-0',
  'sm:text-center',
  'sm:text-xl',
  'sm:top-[35rem]',
  'sm:w-[42rem]',
  'sm:w-[50%]',
  'xl:gap-16',
  'xl:max-w-screen-xl',
  'xl:px-5',
  'xl:px-8',
  'xl:px-9',
  'xl:px-10',
  'xl:px-12',
  'xl:px-16',
  'xl:py-6',
  'xl:py-8',
  'xl:py-24',
  'xl:w-[18%]',
  'xl:w-[19rem]',
  'xl:pl-7',
  'xl:pl-8',
  'xl:pr-16',
  '2xl:px-16',
  '2xl:gap-1',
  'md:w-[calc(100vw-220px)]',
  'lg:w-[calc(100vw-220px)]',
  'xl:w-[calc(100vw-230px)]',
  '2xl:w-[calc(100vw-240px)]',
  '2xl:w-[20.5rem]',
  '2xl:pb-[4.8rem]',
]
