import React, { ReactNode } from 'react'
import {
  Card, Layout,
  Loading,
  SkeletonBodyText, SkeletonDisplayText, SkeletonPage,
  TextContainer,
} from '@shopify/polaris'

type LoadingSkeletonProps = {
  children: ReactNode
  loading: boolean,
}

const LoadingSkeletonComponent = ({ children, loading }:LoadingSkeletonProps) => {
  return loading
    ? (
      <SkeletonPage>
        <Layout>
          <Layout.Section>
            <Loading />
            <Card sectioned>
              <TextContainer>
                <SkeletonDisplayText size='small' />
                <SkeletonBodyText lines={9} />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    ) : (
      <div>
        { children }
      </div>
    )
}

export default LoadingSkeletonComponent
