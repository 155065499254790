import * as React from 'react'

type CircleComponentProps = {
  children: React.ReactNode
  iff?: boolean
  id?: string
  className?: string
  device?:    'ANY'|'DESKTOP'|'MOBILE'
  xl2Screen?: string    // tailwind @media (min-width: 1536px)
  xlScreen?:  string    // tailwind @media (min-width: 1280px)
  lgScreen?:  string    // tailwind @media (min-width: 1024px)
  mdScreen?:  string    // tailwind @media (min-width: 768px)
  smScreen?:  string    // tailwind @media (min-width: 640px)
}

const CircleComponent = ({
  children,
  className='',
}:CircleComponentProps) => {

  const formClass = () => {
    const classes = [
      'flex items-center justify-center',
      'whitespace-pre-wrap text-white font-bold shadow',
      'bg-green-primary h-[65vw] w-[65vw] p-8 rounded-full',
      'sm:text-3xl sm:h-[42vw] sm:w-[42vw]',
      'md:h-[28vw] md:w-[28vw] md:text-2xl',
      'lg:text-3xl lg:h-[26vw] lg:w-[26vw]',
      'xl:text-3xl xl:h-[25vw] xl:w-[25vw] xl:mt-10',
      '2xl:text-4xl 2xl:h-[36rem] 2xl:w-[36rem] 2xl:mt-16',
    ]
    if (className) classes.push(className)
    return classes.join(' ')
  }

  return (
    <div className={formClass()}>
      {children}
    </div>
  )
}

export default CircleComponent
